<template>
  <div>
    <v-card min-height="900px" class=" mx-auto" color="#C3FFF5" flat
      :class="$vuetify.breakpoint.mdAndUp ? 'card-radius' : ''"
    >
      <v-row class="justify-md-end justify-space-between mx-6 pt-4" >
         <v-icon v-if="$vuetify.breakpoint.smAndDown" class="primary--text" large  @click="$router.go(-1)" >mdi-arrow-left-drop-circle-outline</v-icon >
        <lang-swicther />
      </v-row>
      <v-card-text>
        <ValidationObserver ref="observer" v-slot="{ valid,handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <v-row justify="center" align="center">
              <v-card width="90px" elevation="0" class="transparent">
                <v-icon
                  id="male"
                  size="80px"
                  :class="gender === 'male' ? 'blue--text' : ''"
                  @click="gender = 'male'"
                >
                  mdi-human-male
                </v-icon>
                <p class="text-center">
                  <label for="male" class="">{{ $t("signUp.male") }}</label>
                </p>
              </v-card>
              <v-card width="90px" elevation="0" class="transparent">
                <v-icon
                  size="80px"
                  :class="gender === 'female' ? 'blue--text' : ''"
                  class=""
                  id="female"
                  @click="gender = 'female'"
                  >mdi-human-female</v-icon
                >
                <br />
                <p class="text-center">
                  <label for="female">{{ $t("signUp.female") }}</label>
                </p>
              </v-card>
            </v-row>
            <v-card max-width="560px" class="mx-auto transparent" elevation="0">
              
              <div class="mx-3">
                <p class="my-4">{{ "My goal is to" }}</p>
                <v-btn-toggle
                  class="
                    mb-8
                    d-flex
                    flex-column flex-sm-row
                    justify-space-between
                  "
                  v-model="myGoal"
                  dense
                  rounded
                  :background-color="
                    $vuetify.breakpoint.smAndDown ? 'transparent' : ''
                  "
                >
                  <v-btn
                    value="LOSE_WEIGHT"
                    class="grp-btn mb-4 mb-sm-0 flex-grow-1"
                    :class="
                      myGoal === 'LOSE_WEIGHT'
                        ? 'blue white--text'
                        : 'white black--text'
                    "
                  >
                    {{ $t("health.weightLose") }}
                  </v-btn>

                  <v-btn
                    value="MAINTAIN_WEIGHT"
                    class="grp-btn mb-4 mb-sm-0 flex-grow-1"
                    :class="
                      myGoal === 'MAINTAIN_WEIGHT'
                        ? 'blue white--text'
                        : 'white black--text'
                    "
                  >
                    {{ $t("health.maintainWeight") }}
                  </v-btn>
                  <v-btn
                    value="GAIN_WEIGHT"
                    class="grp-btn flex-grow-1"
                    :class="
                      myGoal === 'GAIN_WEIGHT'
                        ? 'blue white--text'
                        : 'white black--text'
                    "
                  >
                    {{ $t("health.gainWeight") }}
                  </v-btn>
                </v-btn-toggle>
              </div>
              <v-row align="center" justify="space-between" class="mx-3 mb-8">
                <p class=" mb-8 mb-sm-0">My current body height in cm</p>
                <v-text-field
                  class="white custom-small-width mt-n2"
                  hide-details=""
                  v-model="height"
                  type="number"
                  outlined
                  rounded
                  dense
                ></v-text-field>
              </v-row>

              <v-row align="center" justify="space-between" class="mx-3 mb-8">
                <p class=" mb-8 mb-sm-0">My current body weight in kg</p>
                <v-text-field
                  class="white custom-small-width mt-n2"
                  hide-details
                  v-model="weight"
                  type="number"
                  outlined
                  rounded
                  dense
                ></v-text-field>
              </v-row>

              <v-row align="center" justify="space-between" class="mx-3 mb-2">
                <p class="my-4">{{ $t("health.allAndDis") }}</p>
              </v-row>
              <v-row class="mx-2 justify-space-between mb-12">
                <v-btn
                  rounded
                  elevation="0"
                  width="100%"
                  @click="dislikesDialog = !dislikesDialog"
                  class="white my-1 black--text custom_btn_healthh"
                >
                  {{ $t("health.dislikes") }}
                  <v-icon right small> mdi-plus </v-icon>
                </v-btn>
                
              </v-row>

              <ValidationProvider rules="">
                <v-textarea
                  full-width
                  rows="5"
                  auto-grow
                  v-model="comment"
                  placeholder="Any other concerns"
                  hide-details=""
                  rounded
                  class="white"
                ></v-textarea>
              </ValidationProvider>
            </v-card>
            <v-row>
              <v-btn
                depressed
                rounded
                color="primary custom-font"
                class="black--text font-weight-light mt-12 mx-auto"
                :width="$vuetify.breakpoint.smAndDown ? 250 : 315"
                type="submit"
                :disabled="!valid"
                :loading="btn_loading"
                >{{ $t("signUp.btnTitle") }}
                <v-icon right> mdi-arrow-right </v-icon>
              </v-btn>
            </v-row>
          </form>
        </ValidationObserver>
        <p v-if="errorFromApi" class="red--text text-center mt-6">
          {{ errorFromApi }}
        </p>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dislikesDialog" max-width="900">
      <v-card class="card-radius secondary">
        <v-card-title>
          <v-row class="justify-end px-4">
            <v-icon
              class="mt-4 black--text"
              @click="dislikesDialog = !dislikesDialog"
            >
              mdi-close-circle
            </v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <dislikes-form />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row>
      <v-dialog v-model="allergiesDialog" max-width="900">
        <v-card max-width="900" class="card-radius secondary pa-0">
          <v-card-title>
            <v-row class="justify-end px-4">
              <v-icon
                class="mt-4 black--text"
                @click="allergiesDialog = !allergiesDialog"
              >
                mdi-close-circle
              </v-icon>
            </v-row>
          </v-card-title>
          <v-card-text>
            <allergies-form :allergies="allergies" />
          </v-card-text>
        </v-card>
      </v-dialog>
      
    </v-row>
  </div>
</template>

<script>
import { updateCustomer } from "../methods/updateCustomer";
export default {
  data() {
    return {
      gender: "male",
      comment: "",
      allergiesDialog: false,
      dislikesDialog: false,
      healthInfo: "HAVE_PLAN",
      myGoal: "LOSE_WEIGHT",
      weight: "50",
      height: "175",
      btn_loading: false,
      errorFromApi: null,
      defaultAddress: true,
      addressType: null,
      customer: {},
      allergies: [],
      dislikes: [],
    };
  },
  components: {
    "allergies-form": () => import("../components/AllergiesForm.vue"),
    "dislikes-form": () => import("../components/DislikesForm.vue"),
    "lang-swicther": () => import("@/core/components/LangSwitch"),
  },
  mounted() {
    this.customer = JSON.parse(localStorage.getItem("Customer"));
    this.$root.$on("selectedAllergies", (data) => {
      this.allergies = data.map((x) => x._id);
      this.allergiesDialog = false;
    });
    this.$root.$on("selectedDislikes", (data) => {
      this.dislikes = data.map((x) => x._id);
      this.dislikesDialog = false;
    });
  },
  computed: {
    maxWidth() {
      let width = 800;
      if (this.$vuetify.breakpoint.md) width = 620;
      if (this.$vuetify.breakpoint.lgAndUp) width = 950;
      if (this.$vuetify.breakpoint.sm) width = 700;
      if (this.$vuetify.breakpoint.xs) width = 380;
      return width;
    },
  },
  methods: {
    onSubmit() {
      debugger
      this.$refs.observer.validate();
      this.btn_loading = true;
      const input = {
        customerId: this.customer.customer._id,
        userId: this.customer.user._id,
        gender: this.gender,
        customerGoal: this.myGoal,
        comment: this.comment,
        height: this.height,
        weight: this.weight,
        dislikes: this.dislikes,
        allergies: this.allergies,
      };
      updateCustomer(input).then((response) => {
        if (response.data.updateCustomer) {
          this.$router.push("/package");
          this.btn_loading = false;
        }
      });
    },
  },
  beforeDestroy() {
    this.$root.$off("selectedAllergies");
    this.$root.$off("selectedDislikes");
  },
};
</script>

<style scoped>
.custom-small-width {
  max-width: 130px !important;
}
.grp-btn {
  letter-spacing: 1px;
}
.custom-btn-health {
  width: 180px;
}
.custom_btn_health {
  width: 175px;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    border-color: rgba(241, 224, 224, 0) !important;
}
</style>
